let world = document.querySelector("html");
let toggle = document.querySelector('#hamburger');
let mainNav = document.querySelector('#main-nav');
let customNav = document.querySelector('.custom-nav');
toggle.addEventListener('click', function(e) {
    e.preventDefault();
    world.classList.toggle('no-scroll')
    toggle.classList.toggle('is-active')
    mainNav.classList.toggle('is-active');
    customNav.classList.toggle('is-active');
    socialButtons.classList.remove('is-active');
});

let btnContacto = document.querySelector('#btnContacto');
let socialButtons = document.querySelector('.social-links');
btnContacto.addEventListener('click', function(e){
    e.preventDefault();
    socialButtons.classList.toggle('is-active');
    this.classList.toggle('current');
})